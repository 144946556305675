import {FC, useState, useEffect} from 'react'
import Image from 'next/image'

type Props = {
	title?: string
}

const Header: FC<Props> = ({ title }) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [visible, setVisible] = useState(true)

  const handleScroll = () => {
    const currentScrollPos = window.scrollY

    if(currentScrollPos > prevScrollPos){
        setVisible(false)
    } else {
        setVisible(true)
    }

    setPrevScrollPos(currentScrollPos)
  }

  useEffect( () => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <header id="header" className={` backdrop-blur-sm bg-white/30 w-full fixed left-0 ${visible ? 'top-0' : '-top-20'} z-[1000] transition-all`}>
      <div className="inner flex h-20 items-center justify-between gap-10 py-2">
        <div className="">
          <span className="sr-only">{title ?? 'Bryan Suarez'}</span>
          <Image
            className="h-11 w-auto"
            src="/images/mono-black.png"
            alt={title ?? 'Bryan Suarez'}
            width={1512}
            height={1288}
            loading='eager'
          />
        </div>
        <div className="">
          <button type='button' className='rounded-3xl border border-primary px-6 py-3 text-base transition-colors hover:bg-primary hover:text-white'>Get in Touch</button>
        </div>
      </div>
    </header>
  )
}

export default Header