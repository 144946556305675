import { FC, useRef } from 'react'
import { motion, useScroll, useTransform, MotionValue } from 'framer-motion'

type Props = {
  direction: string,
  left: string,
  scrollYProgress: MotionValue<number>,
  children: React.ReactNode,
}

const ParallaxText: FC<Props> = ({direction = 'right', left = '0', scrollYProgress, children}) => {
  const directionV = direction == 'left' ? -1 : 1
  const translateX = useTransform(scrollYProgress, [0, 1], [300 * directionV, -300 * directionV])

  return (
    <motion.div style={{x: translateX, left: left}} className="relative flex whitespace-nowrap">
      {children}
    </motion.div>
  )
}

export default ParallaxText