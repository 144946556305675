import { FC } from 'react'
import { HeartIcon } from '@heroicons/react/24/solid'

type Props = {}

const Footer: FC<Props> = () => {
	return (
    <footer id="footer" className="border-t border-t-gray-200 bg-white py-20">
      <div className="inner">
        <h4 className='mb-5 mt-1 text-center md:text-left'>Crafting seamless web experiences, one line of code at a time.</h4>
        <div className="flex flex-col items-center justify-between text-sm text-gray-500 md:flex-row">
          <p>&copy; Copyright {new Date().getFullYear()}. All Rights Reserved.</p>
          <p className="inline-flex justify-center">Made with <HeartIcon className="ml-1 h-5 w-5 text-red-600" aria-hidden="true" /> by Bryan.</p>
        </div>
      </div>
    </footer>
	)
}

export default Footer