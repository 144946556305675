import {FC} from 'react'
import { motion } from 'framer-motion'

type Props = {}

const Banner: FC<Props> = () => {

  return (
    <section className="flex w-full items-center bg-white pb-20 pt-40 lg:min-h-screen">
      <div className="inner">
        <h1 className="main-title banner-title">
          <div className='inline-block overflow-hidden'>
            <motion.span
              initial={{ y: '100%' }}
              animate="visible"
              style={{ display: 'inline-block', willChange: 'transform' }}
              variants={{
                visible: i => ({
                  y: 0,
                  transition: {
                    delay: .1
                  }
                })
              }}
            >
              Crafting
            </motion.span>
            &nbsp;
            <motion.span
              initial={{ y: '100%' }}
              animate="visible"
              style={{ display: 'inline-block', willChange: 'transform' }}
              className='background-animate bg-gradient-to-r from-primary via-green-500 to-primary bg-clip-text text-transparent'
              variants={{
                visible: i => ({
                  y: 0,
                  transition: {
                    delay: .2
                  }
                })
              }}
            >
              Code
            </motion.span>
          </div><br />
          <div className="-mt-4 inline-block overflow-hidden">
            <motion.span
              initial={{ y: '100%' }}
              animate="visible"
              style={{ display: 'inline-block', willChange: 'transform' }}
              variants={{
                visible: i => ({
                  y: 0,
                  transition: {
                    delay: .3
                  }
                })
              }}
            >
              Building 
            </motion.span>
            &nbsp;
            <motion.span
              initial={{ y: '100%' }}
              animate="visible"
              style={{ display: 'inline-block', willChange: 'transform' }}
              variants={{
                visible: i => ({
                  y: 0,
                  transition: {
                    delay: .4
                  }
                })
              }}
            >
              Solutions
            </motion.span>
          </div>
        </h1>

        <div className="mt-10 flex grow gap-5 overflow-hidden text-lg sm:text-xl md:text-2xl">
          <div className="relative h-16 w-16 rounded-full bg-font-color text-white">
            <div className="slider__word absolute left-0 top-0 flex h-full w-full items-center justify-center text-center text-white" style={{animationDelay: '0'}}>10</div>
            <div className="slider__word absolute left-0 top-0 flex h-full w-full items-center justify-center text-center text-white" style={{animationDelay: '3000ms'}}>100+</div>
          </div>
          <div className="relative grow">
            <div className="slider__word absolute left-0 top-0 flex h-full w-full items-center text-font-color" style={{animationDelay: '0'}}>Years of Building Exceptional Websites</div>
            <div className="slider__word absolute left-0 top-0 flex h-full w-full items-center text-font-color" style={{animationDelay: '3000ms'}}>Websites Delivered: Proven Excellence in Web Development</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner