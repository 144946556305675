import { FC, useRef, useEffect, useState } from 'react'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Image from 'next/image'

type Props = {}

const Work: FC<Props> = () => {
  const [windowSize, setWindowSize] = useState(0)
  const [enabled, setEnabled] = useState(false)
  const pinContainer = useRef<HTMLElement | null>(null)
  const pinContent = useRef<HTMLDivElement | null>(null)
  const workItems = useRef<HTMLDivElement[]>([])

  // Reload page if resize is greater than 1023 to refresh sa gsap offset
  useEffect( () => {
    const updateWindowSize = () => {
      setWindowSize(window.innerWidth)

      if (window.innerWidth > 1023) {
        window.location.reload() 
      }
    }
    
    setWindowSize(window.innerWidth)
    // updateWindowSize() 

    window.addEventListener('resize', updateWindowSize) 
    
    return () => {
        window.removeEventListener('resize', updateWindowSize)
    }
  }, [] )

  useGSAP(
    () => {
      if (windowSize > 1023) {
        gsap.registerPlugin(ScrollTrigger)

        const containerAnimation = gsap.to(
          pinContent.current,
          {
            scrollTrigger: {
              trigger: pinContainer.current,
              start: 'top top',
              end: () => '+=' + pinContent.current!.offsetWidth,
              pin: true,
              scrub: true,
            },
            x: () => -(pinContent.current!.scrollWidth - document.documentElement.clientWidth) + 'px',
            ease: 'none'
          }
        )

        workItems.current.forEach(element => {
          gsap.to(element, {
            scrollTrigger: {
              trigger: element,
              start: 'left center',
              end: 'right center',
              containerAnimation: containerAnimation,
              toggleClass: {
                targets: element,
                className: 'active'
              }
            }
          })
        })
      }
    },
    {
      dependencies: [pinContainer, pinContent, workItems, windowSize],
    }
  )

  return (
    <section ref={pinContainer} className="flex min-h-screen w-full items-center justify-start overflow-hidden bg-white py-10 lg:py-24">
      <div ref={pinContent} className="inner flex flex-col gap-10 md:grid md:grid-cols-2 md:grid-rows-2 lg:flex lg:h-full lg:w-auto lg:flex-row lg:flex-nowrap lg:items-center lg:gap-20 lg:!pr-0">
        <div ref={((el) => workItems.current[1] === el)} className="card-sm overflow-hidden md:aspect-[1.2] lg:aspect-[1.46]">
          <h2>Work</h2>
          <h4 className='font-normal'>Innovative websites, responsive designs, and tailored digital experiences.</h4>
          {/* <p>Expert freelance web developer turning ideas into sleek, responsive websites that work perfectly on any device.</p> */}
        </div>
        <div ref={((el) => workItems.current[2] === el)} className="card-lg relative overflow-hidden rounded-[2rem] bg-black md:aspect-[1.2]">
          <Image
            className="h-[300px] w-full object-cover object-center opacity-90 md:h-full"
            src="/images/placeholder.jpg"
            alt={''}
            width={1300}
            height={1658}
            loading='lazy'
          />
          <div className="absolute bottom-0 left-0 z-10 p-5 md:p-10">
            <h3 className="!mb-0 text-white">Sample</h3>
            
            <div className="mt-3 flex gap-5">
              <div className="rounded-3xl border border-white bg-black/30 px-3 py-3 text-base font-semibold text-white backdrop-blur-sm md:px-6">Laravel</div>
              <div className="rounded-3xl border border-white bg-black/30 px-3 py-3 text-base font-semibold text-white backdrop-blur-sm md:px-6">CRM</div>
            </div>
          </div>
        </div>
        <div ref={((el) => workItems.current[3] === el)} className="card-lg relative overflow-hidden rounded-[2rem] bg-black md:aspect-[1.2]">
          <Image
            className="h-[20rem] w-full object-cover object-center opacity-90 md:h-full"
            src="/images/placeholder.jpg"
            alt={''}
            width={1300}
            height={1658}
            loading='lazy'
          />
          <div className="absolute bottom-0 left-0 z-10 p-5 md:p-10">
            <h3 className="!mb-0 text-white">Sample</h3>
            
            <div className="mt-3 flex gap-5">
              <div className="rounded-3xl border border-white bg-black/30 px-3 py-3 text-base font-semibold text-white backdrop-blur-sm md:px-6">WordPress</div>
              <div className="rounded-3xl border border-white bg-black/30 px-3 py-3 text-base font-semibold text-white backdrop-blur-sm md:px-6">E-Commerce</div>
            </div>
          </div>
        </div>
        <div ref={((el) => workItems.current[4] === el)} className="card-lg relative overflow-hidden rounded-[2rem] bg-black md:aspect-[1.2]">
          <Image
            className="h-[300px] w-full object-cover object-center opacity-90 md:h-full"
            src="/images/placeholder.jpg"
            alt={''}
            width={1300}
            height={1658}
            loading='lazy'
          />
          <div className="absolute bottom-0 left-0 z-10 p-5 md:p-10">
            <h3 className="!mb-0 text-white">Sample</h3>
            
            <div className="mt-3 flex gap-5">
              <div className="rounded-3xl border border-white bg-black/30 px-3 py-3 text-base font-semibold text-white backdrop-blur-sm md:px-6">Laravel</div>
              <div className="rounded-3xl border border-white bg-black/30 px-3 py-3 text-base font-semibold text-white backdrop-blur-sm md:px-6">Digital Product</div>
            </div>
          </div>
        </div>
        <div ref={((el) => workItems.current[5] === el)} className="card-lg relative overflow-hidden rounded-[2rem] bg-black md:aspect-[1.2]">
          <Image
            className="h-[300px] w-full object-cover object-center opacity-90 md:h-full"
            src="/images/placeholder.jpg"
            alt={''}
            width={1300}
            height={1658}
            loading='lazy'
          />
          <div className="absolute bottom-0 left-0 z-10 p-5 md:p-10">
            <h3 className="!mb-0 text-white">Sample</h3>
            
            <div className="mt-3 flex gap-5">
              <div className="rounded-3xl border border-white bg-black/30 px-3 py-3 text-base font-semibold text-white backdrop-blur-sm md:px-6">Laravel</div>
              <div className="rounded-3xl border border-white bg-black/30 px-3 py-3 text-base font-semibold text-white backdrop-blur-sm md:px-6">Digital Product</div>
            </div>
          </div>
        </div>
        <div ref={((el) => workItems.current[6] === el)} className="card-lg relative overflow-hidden rounded-[2rem] bg-black md:aspect-[1.2]">
          <Image
            className="h-[300px] w-full object-cover object-center opacity-90 md:h-full"
            src="/images/placeholder.jpg"
            alt={''}
            width={1300}
            height={1658}
            loading='lazy'
          />
          <div className="absolute bottom-0 left-0 z-10 p-5 md:p-10">
            <h3 className="!mb-0 text-white">Sample</h3>
            
            <div className="mt-3 flex gap-5">
              <div className="rounded-3xl border border-white bg-black/30 px-3 py-3 text-base font-semibold text-white backdrop-blur-sm md:px-6">Laravel</div>
              <div className="rounded-3xl border border-white bg-black/30 px-3 py-3 text-base font-semibold text-white backdrop-blur-sm md:px-6">Digital Product</div>
            </div>
          </div>
        </div>
        <div ref={((el) => workItems.current[8] === el)} className="w-0">&nbsp;</div>
      </div>
    </section>
  )
}

export default Work