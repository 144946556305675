import { FC, useRef } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import ParallaxText from '../parallax-text'

type Props = {
}

const AboutMe: FC<Props> = () => {
  const container = useRef(null)
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start end', 'end start']
  })

  return (
    <section className="relative overflow-hidden py-24 sm:py-24" ref={container}>
      <ParallaxText direction='right' left='-5%' scrollYProgress={scrollYProgress}>
        <span className='text-[10vw] font-bold text-black/95'>Empower Your Brand</span>
      </ParallaxText>

      <ParallaxText direction='left' left='-7%' scrollYProgress={scrollYProgress}>
        <span className='text-[10vw] font-bold text-black/95'>Elevate with Creativity</span>
      </ParallaxText>
    </section>
  )
}

export default AboutMe