'use client'

import { useEffect, } from 'react'

import Header from '../components/header'
import Banner from '../components/banner'
import Work from '../components/work'
import Footer from '../components/footer'
import Services from '../components/services'
import AboutMe from '../components/about-me'

export default function Home() {
  useEffect( () => {
    (
      async () => {
        const LocomotiveScroll = (await import('locomotive-scroll')).default
        const locomotiveScroll = new LocomotiveScroll({
          lenisOptions: {
            wrapper: window,
            content: document.documentElement,
            lerp: 0.1,
            duration: 1.2,
            orientation: 'vertical',
            gestureOrientation: 'vertical',
            smoothWheel: true,
            wheelMultiplier: 1,
            touchMultiplier: 2,
            easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t))
          },
        })
      }
    )()
  }, [])

  return ( 
    <div>
      <Header />
      <Banner />
      <Work />
      <AboutMe />
      <Services
        title='Services I provide'
        subtitle='As a Full-Stack Web Developer'
        description='A wide range of services related to building, and maintaining websites and web applications.'
        items={[
          {
            name: 'Front-End Development',
            description:
              'Implementing website designs using HTML, CSS, and JavaScript to ensure the website\'s responsive and interactive features.',
            icon: 'ComputerDesktopIcon',
          },
          {
            name: 'Back-End Development',
            description:
              'Building the server-side of websites using programming PHP language. This involves handling databases, server logic, and API integrations.',
            icon: 'CodeBracketSquareIcon',
          },
          {
            name: 'Content Management System (CMS)',
            description:
              'Integrating websites with popular CMS platforms like WordPress, to facilitate easy content updates and management.',
            icon: 'PencilSquareIcon',
          },
          {
            name: 'Web Performance Optimization',
            description:
              'Improving website speed and performance to enhance user experience and search engine rankings.',
            icon: 'AdjustmentsHorizontalIcon',
          },
          {
            name: 'Search Engine Optimization (SEO)',
            description:
              'Implementing SEO best practices to improve the visibility and ranking of websites in search engine results.',
            icon: 'PresentationChartBarIcon',
          },
          {
            name: 'Web Analytics',
            description:
              'Integrating and setting up tools like Google Analytics to track website traffic, user behavior, and other relevant data.',
            icon: 'PresentationChartLineIcon',
          },
          {
            name: 'Website Accessibility',
            description:
              'Ensuring that websites are accessible to people with disabilities, complying with web accessibility standards (e.g., WCAG guidelines).',
            icon: 'LifebuoyIcon',
          },
          {
            name: 'Website Maintenance',
            description:
              'Providing ongoing support and updates for websites to keep them running smoothly and up-to-date.',
            icon: 'WrenchScrewdriverIcon',
          },
        ]} 
      />
      <Footer />
    </div>
  )
}