'use client'

import { FC } from 'react'
import { 
  ComputerDesktopIcon,
  CodeBracketSquareIcon,
  PencilSquareIcon,
  AdjustmentsHorizontalIcon,
  PresentationChartBarIcon,
  PresentationChartLineIcon,
  LifebuoyIcon,
  WrenchScrewdriverIcon 
} from '@heroicons/react/24/outline'

type Props = {
  title: string
  subtitle: string
  description: string
	items: {
		name: string
		description: string
    icon: string
	}[]
}

const Services: FC<Props> = ({ title, subtitle, description, items }) => {
  return (
    <div className="overflow-hidden bg-white py-16 sm:py-24">

      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-primary">{title}</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {subtitle}
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {description}
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {items.map((item, index) => {
              return (
                <div key={item.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="background-animate absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-gradient-to-r from-primary via-green-500 to-primary">
                      {item.icon === 'ComputerDesktopIcon' && <ComputerDesktopIcon className="h-6 w-6 text-white" aria-hidden="true" />}
                      {item.icon === 'CodeBracketSquareIcon' && <CodeBracketSquareIcon className="h-6 w-6 text-white" aria-hidden="true" />}
                      {item.icon === 'PencilSquareIcon' && <PencilSquareIcon className="h-6 w-6 text-white" aria-hidden="true" />}
                      {item.icon === 'AdjustmentsHorizontalIcon' && <AdjustmentsHorizontalIcon className="h-6 w-6 text-white" aria-hidden="true" />}
                      {item.icon === 'PresentationChartBarIcon' && <PresentationChartBarIcon className="h-6 w-6 text-white" aria-hidden="true" />}
                      {item.icon === 'PresentationChartLineIcon' && <PresentationChartLineIcon className="h-6 w-6 text-white" aria-hidden="true" />}
                      {item.icon === 'LifebuoyIcon' && <LifebuoyIcon className="h-6 w-6 text-white" aria-hidden="true" /> }
                      {item.icon === 'WrenchScrewdriverIcon' && <WrenchScrewdriverIcon className="h-6 w-6 text-white" aria-hidden="true" />}
                    </div>
                    {item.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">{item.description}</dd>
                </div>
              )
            })}
          </dl>
        </div>
      </div>

      {/* <div className="fixed left-0 top-0 h-screen w-full bg-black"></div> */}
    </div>
  )
}

export default Services